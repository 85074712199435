import PDFViewer from "../lib/constants/pdfViewer";
import { useLocation } from "react-router-dom";

const PdfViewPage = () => {
  const location = useLocation();
  const { report } = location.state;
  return (
    <span>
      <PDFViewer url={report} />
    </span>
  );
};

export default PdfViewPage;
