import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from "../lib/constants/loadingScreen";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const AddNewAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [actionLoading, setActionLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [problem, setProblem] = useState("");
  const [patientSelectedId, setPatientSelectedId] = useState(null);
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [patientStoringValue, setPatientStoringValue] = useState("");
  const [patientSelectedContact, setPatientSelectedContact] = useState("");
  const [patientSelectedAge, setPatientSelectedAge] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");
  const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  const [dateSelectedId, setDateSelectedId] = useState("");
  const [dateStoringValue, setDateStoringValue] = useState("");
  const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  const [timeSelectedId, setTimeSelectedId] = useState("");
  const [timeStoringValue, setTimeStoringValue] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewpatientsstaff`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((patient) => ({
            value: patient._id,
            label: patient.fullname,
            age: patient.age,
            contact: patient.phone,
          }));
          setPatientDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        message.error("Failed to get user details! Please try again.");
      });
  }, []);

  // handle changes for patient dropdown..
  const handlePatientInputChange = (selectedPatient) => {
    if (selectedPatient) {
      setPatientStoringValue(selectedPatient);
      setPatientSelectedId(selectedPatient.value);
      setPatientSelectedAge(selectedPatient.age);
      setPatientSelectedContact(selectedPatient.contact);
      setPatientSelectedName(selectedPatient.label);
    }
  };

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
      setDateStoringValue("");
      setTimeStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      setDateStoringValue("");
      setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  useEffect(() => {
    if (doctorSelectedId) {
      axios
        .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((date) => ({
              value: date.date,
              label: date.date,
              time: date.time,
            }));
            setSelectedDateDetails(options);
          }
        });
    }
  }, [doctorSelectedId]);

  const handleDateInputChange = (selectedDate) => {
    if (selectedDate) {
      setDateStoringValue(selectedDate);
      setDateSelectedId(selectedDate.value);
      setSelectedTimeDetails(selectedDate.time);
      setTimeStoringValue("");
    }
  };

  // handle time for selected date:
  const timeDataOptions = timeSelectedDetails.map((time) => ({
    value: time,
    label: time,
  }));

  const handleTimeInputChange = (selectedTime) => {
    if (selectedTime) {
      setTimeStoringValue(selectedTime);
      setTimeSelectedId(selectedTime.value);
    }
  };

  const createAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      patientId: patientSelectedId,
      fullname: patientSelectedName,
      age: patientSelectedAge,
      mobile: patientSelectedContact,
      problem,
      department: departmentSelectedId,
      doctorId: doctorSelectedId,
      date: dateSelectedId,
      time: timeSelectedId,
    };

    axios
      .post(`${apiBaseUrl}/staff/patient/bookDoctorAppointment`, data, config)
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Appointment Created Successfully.",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/viewappointment");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error booking appointment:", err);
        message.error("Error booking appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <DashboardStatsGrid />
      <div>
        <form
          className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={createAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Create appointment</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div>
                <label className="text-base">Patient Name</label>
                <Select
                  options={patientDetails}
                  placeholder="Select patient"
                  onChange={handlePatientInputChange}
                  value={patientStoringValue}
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0.375rem",
                    }),
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Contact</label>
                <input
                  value={patientSelectedContact}
                  onChange={(e) => {
                    setPatientSelectedContact(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Contact"
                  required
                />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <label className="text-base ms-1">Age</label>
                <input
                  onChange={(e) => {
                    setPatientSelectedAge(e.target.value);
                  }}
                  value={patientSelectedAge}
                  className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Age"
                  required
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Problem</label>
              <input
                onChange={(e) => {
                  setProblem(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="Problem"
                value={problem}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={departmentStoringValue}
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={doctorStoringValue}
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Date</label>
              <Select
                options={dateSelectedDetails}
                value={dateStoringValue}
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Time</label>
              <Select
                options={timeDataOptions}
                value={timeStoringValue}
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default AddNewAppointmentStaff;
