import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Image, Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";

const ViewPharmaStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setactionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/viewpharmastaffs`, config)
      .then((response) => {
        setDetails(response.data);
        setFilteredDetails(response.data);
      })
      .catch((e) => {
        console.error("Failed to get pharma details", e);
        toast.error("Failed to get pharma details! Please try again later", {
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteStaff = (pharma_staff_id) => {
    if (window.confirm("Are you sure want to delete this pharma staff?")) {
      setactionLoading(true);
      axios
        .delete(`${apiBaseUrl}/pharmastaff/${pharma_staff_id}`, config)
        .then((response) => {
          if (response.data.message) {
            toast.success("Pharma staff member deleted", {
              position: "bottom-right",
            });
            window.location.reload();
          } else {
            toast.error("Staff Not Deleted", {
              position: "bottom-right",
            });
          }
        })
        .catch((e) => {
          console.error("Failed to delete pharma staff", e);
          toast.error(
            "Failed to delete pharma staff! Please try again later.",
            {
              position: "bottom-right",
            }
          );
        })
        .finally(() => {
          setactionLoading(false);
        });
    }
  };
  const filteredPharmaStaffs = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (staff) =>
            staff?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            staff?.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            staff?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            staff?.phone?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchData();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredPharmaStaffs(searchTerm);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "picture",
      key: "picture",
      render: (picture) => (
        <Image
          src={picture}
          width={80}
          height={80}
          className="object-contain rounded-md"
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Password",
      render: (_, record) => (
        <Link
          to={`/dashboard/reset/password/${record._id}`}
          className="text-indigo-600 hover:text-indigo-900"
        >
          <button className="text-center">
            <span className="text-sky-600">
              <MdEditSquare size={21} />
            </span>
          </button>
        </Link>
      ),
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/pharmastaff/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteStaff(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      <ToastContainer />

      {actionLoading && <LoadingScreen />}
      <DashboardStatsGrid />

      <div class="container max-w-8xl mx-auto mt-8" id="viewPharmaStaff">
        <div class="mb-4">
          <h1 class="text-3xl font-bold decoration-gray-400">Pharma Staffs</h1>
          <div className="flex justify-between mt-2">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <div>
              <Link to="/dashboard/addpharmastaff">
                <button class="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                  Add Pharma Staff
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewPharmaStaff;
