import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Spin } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Button } from "antd";
import debounce from "lodash/debounce";

const BillTable = ({ data }) => {
  const navigate = useNavigate();
  const { bills, loading } = data;
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!loading) {
      setDataSource(bills);
    }
  }, [bills, loading]);

  const filteredData = useCallback(
    debounce((searchText) => {
      if (searchText.trim() !== "") {
        const filtered = bills.filter((record) =>
          Object.keys(record).some((key) => {
            const value = record[key];
            if (key === "patient") {
              return Object.values(value).some(
                (subValue) =>
                  subValue &&
                  subValue
                    .toString()
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
              );
            } else {
              return (
                value &&
                value
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            }
          })
        );
        setDataSource(filtered);
      } else {
        setDataSource(bills);
      }
    }, 500),
    [searchText, bills]
  );

  const generateBill = () => {
    navigate("/dashboard/generate/bill");
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    filteredData(value);
  };

  const convertToNepaliTime = (utcTime) => {
    return moment
      .utc(utcTime)
      .tz("Asia/Kathmandu")
      .format("DD-MMMM-YYYY h:mm A");
  };

  const columns = [
    {
      title: "Bill No",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Patient Name",
      dataIndex: ["patient", "fullname"],
      key: "patientName",
    },
    {
      title: "Age",
      dataIndex: ["patient", "age"],
      key: "age",
    },
    {
      title: "Mobile",
      dataIndex: ["patient", "mobile"],
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: ["patient", "address"],
      key: "address",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/bill/${record._id}`}
            className="bg-gray-500 hover:bg-gray-600 hover:text-white text-white font-bold rounded-lg shadow-md py-2 px-4 me-2"
          >
            Update
          </Link>
          <Link
            to={`/dashboard/pharmacy/print-invoice/${record._id}`}
            className="bg-gray-50 border hover:bg-gray-100 hover:text-black text-black font-bold rounded-lg shadow-md py-2 px-4"
          >
            Print
          </Link>
        </>
      ),
    },
  ];

  return (
    <div className="pe-4 pt-4">
      <div className="flex justify-between items-center mb-4">
        <Input
          placeholder="Search"
          prefix={<SearchOutlined />}
          allowClear
          value={searchText}
          onChange={handleSearch}
          style={{ width: 300 }} // Adjust width here
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={generateBill}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg shadow-md"
        >
          Add Bill
        </Button>
      </div>

      <Table
        className="rounded-md shadow-md"
        dataSource={dataSource}
        columns={columns}
        rowKey="_id"
        pagination={{
          className: "pe-3",
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50"],
        }}
        loading={{
          indicator: <Spin size="large" />,
          spinning: loading,
        }}
      />
    </div>
  );
};

export default BillTable;
