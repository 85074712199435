import { Component } from "react";
import ErrorPageHandler from "./ErrorPageHandler";

// A higher-order component (HOC) for error handling.
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // A static method to update state when an error occurs in any child component.
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // A lifecycle method called when an error is caught.
  componentDidCatch(error, errorInfo) {
    // You can log the error or perform other actions here.
    if (this.props.onHandleError) {
      this.props.onHandleError(error, errorInfo);
    }
  }

  render() {
    // If an error has occurred, render the ErrorPage component.
    if (this.state.hasError) {
      return <ErrorPageHandler />;
    }
    // Otherwise, render the children provided to the ErrorBoundary.
    return this.props.children;
  }
}

export default ErrorBoundary;
