import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Space,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Title } = Typography;

const AddBill = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicineTableData, setMedicineTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [form] = Form.useForm();

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/all_appointment_pagination`)
      .then((response) => {
        if (response.data.success) {
          setAppointments(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/medicine`)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  }, []);

  const fetchPatientDetails = (appointmentId) => {
    axios
      .get(`${apiBaseUrl}/appointment/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setSelectedPatient(response.data.data);
          form.setFieldsValue({
            patient: response.data.data,
          });
          form.setFieldsValue({
            userID: response.data.data.patientId,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching patient details:", error);
      });
  };

  const handleAppointmentChange = (appointmentId) => {
    setSelectedAppointment(appointmentId);
    fetchPatientDetails(appointmentId);
  };

  const handleAddMedicine = () => {
    const values = form.getFieldsValue(["medicine", "quantity"]);
    if (values.medicine != null && values.quantity != null) {
      const selectedMedicine = medicineOptions.find(
        (med) => med._id === values.medicine
      );
      const newItem = {
        key: selectedMedicine._id,
        medicineId: selectedMedicine._id,
        medicine: selectedMedicine.name,
        quantity: values.quantity,
        price: selectedMedicine.price,
        total: selectedMedicine.price * values.quantity,
      };
      setMedicineTableData([...medicineTableData, newItem]);

      form.setFieldsValue({
        items: [...medicineTableData, newItem], // Update form value with items array
      });
      form.resetFields(["medicine", "quantity", "price"]);
    } else {
      message.warning("Please select a medicine and quantity.");
    }
  };

  const handleDeleteMedicine = (key) => {
    const updatedTableData = medicineTableData.filter(
      (item) => item.key !== key
    );
    setMedicineTableData(updatedTableData);
  };

  const columns = [
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price/quantity",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>Edit</a>
          <a
            onClick={() => handleDeleteMedicine(record.key)}
            className="text-red-500 hover:text-red-600"
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    const { appointment, patient } = values;
    const { fullname, age, gender, mobile, address } = patient;

    const items = medicineTableData.map((item) => ({
      medicine: item.medicineId,
      quantity: parseInt(item.quantity),
    }));

    const data = {
      appointment,
      regNo: "REGNO123414",
      items,
      patient: {
        fullname,
        age,
        gender,
        mobile,
        address,
      },
    };

    if (items.length !== 0) {
      axios
        .post(`${apiBaseUrl}/bills`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success(
              "Bill added successfully!",
              0.6,
              function onClose() {
                navigate(
                  `/dashboard/pharmacy/print-invoice/${response.data.data._id}`
                );
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          message.error("Failed to add bill. Please try again later.");
        });
    } else {
      message.error("Medicine table cannot be empty.");
    }
  };

  return (
    <div className="bg-white rounded-md p-5 shadow-md">
      <div className="font-medium text-sky-600 text-4xl text-center mb-2">
        Create Medicine Bill
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={3}>Patient Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              label="Select Appointment"
              name="appointment"
              rules={[{ message: "Please select an appointment" }]}
            >
              <Select
                placeholder="Select an appointment"
                showSearch
                onChange={handleAppointmentChange}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {appointments.map((appointment) => (
                  <Option key={appointment._id} value={appointment._id}>
                    {appointment.appointmentNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Patient Name"
              name={["patient", "fullname"]}
              rules={[{ required: true, message: "Please enter patient name" }]}
            >
              <Input placeholder="Enter fullname" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Age"
              name={["patient", "age"]}
              rules={[{ required: true, message: "Please enter patient age" }]}
            >
              <Input placeholder="Enter Age" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="UserID"
              name={["userID", "userID"]}
              rules={[{ message: "UserID" }]}
            >
              <Input placeholder="Empty for non-registered users" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Mobile"
              name={["patient", "mobile"]}
              rules={[
                {
                  required: true,
                  message: "Please enter patient mobile number",
                },
              ]}
            >
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Address"
              name={["patient", "address"]}
              rules={[
                { required: true, message: "Please enter patient address" },
              ]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Gender"
              name={["patient", "gender"]}
              rules={[
                { required: true, message: "Please enter patient gender" },
              ]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>Medicine</Title>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label="Medicine"
              name="medicine"
              rules={[{ message: "Please select a medicine" }]}
            >
              <Select
                placeholder="Select a medicine"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {medicineOptions.map((med, index) => (
                  <Option key={index} value={med._id}>
                    {med.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ message: "Please enter quantity" }]}
            >
              <Input type="number" placeholder="Enter medicine quantity" />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddMedicine}
              className="bg-gray-200 text-black rounded shadow-md"
            >
              Add Medicine
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={medicineTableData}
          pagination={false}
        />

        <Form.Item>
          <div
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              id="addStaffBtn"
              className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-3 px-6 font-semibold text-md rounded-md" // Add rounded-md class for rounded corners
              type="submit"
              style={{ width: "200px" }}
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBill;
