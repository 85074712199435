import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Flex, Spin, message, Row, Col } from "antd";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Maps.scss";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const UpdateMedicine = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { medId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [expDate, setExpDate] = useState("");
  const [manfDate, setManufactureDate] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/medicine/${medId}`, config)
      .then((response) => {
        // handeling date fetched from database
        const fetchedExpDate = response.data.data.expDate;
        const extractedExpDate = fetchedExpDate.split("T")[0];
        setExpDate(extractedExpDate);

        const fetchedManfDate = response.data.data.manfDate;
        const extractedManfDate = fetchedManfDate.split("T")[0];
        setManufactureDate(extractedManfDate);
        form.setFieldsValue({
          name: response.data.data.name,
          type: response.data.data.type,
          affiliateName: response.data.data.affiliateName,
          // expDate: formattedExpDate,
          // manfDate: formattedManfDate,
          quantity: response.data.data.quantity,
          batch: response.data.data.batch,
          price: response.data.data.price,
          dosage: response.data.data.dosage,
        });
      })
      .catch((error) => {
        console.error("Error fetching medicine details", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMedicine = async (values) => {
    setActionLoading(true);

    const { affiliateName, batch, dosage, name, price, quantity, type } =
      values;

    const data = {
      name,
      type,
      affiliateName,
      quantity,
      batch,
      price,
      dosage,
      expDate,
      manfDate,
    };

    try {
      const response = await axios.put(
        `${apiBaseUrl}/update/medicine/${medId}`,
        data,
        config
      );

      if (response.data.success === true) {
        toast.success("Medicine Updated.", {
          position: "bottom-right",
        });
        setTimeout(() => {
          window.location.replace("/dashboard/medicine");
        }, 1000);
      } else {
        toast.error(response.error.msg, {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error("Failed to update medicine:", error);
      toast.error("Failed to update medicine! Please try again.", {
        position: "bottom-right",
      });
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {(actionLoading || loading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      )}
      <DashboardStatsGrid />
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={updateMedicine}
          className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-2"
        >
          <div className="text-gray-600 font-medium text-3xl mb-4">
            Update Medicine
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Medicine Name"
                name="name"
                rules={[
                  { required: true, message: "Please input medicine name!" },
                ]}
              >
                <Input placeholder="Medicine name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Medicine Type"
                name="type"
                rules={[
                  { required: true, message: "Please input medicine type!" },
                ]}
              >
                <Input placeholder="Medicine type (e.g., Tablets/Capsules...)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Affiliate Name"
                name="affiliateName"
                rules={[
                  { required: true, message: "Please input affiliate name!" },
                ]}
              >
                <Input placeholder="Affiliate Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Batch"
                name="batch"
                rules={[
                  { required: true, message: "Please input batch number!" },
                ]}
              >
                <Input placeholder="Batch number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label>Manufacture Date</label>
              <input
                id="manufactureDate"
                value={manfDate}
                type="date"
                onChange={(e) => {
                  setManufactureDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Manufacture Date"
                required
              />
            </Col>
            <Col span={12}>
              <label>Expiry Date</label>
              <input
                id="expDate"
                type="date"
                value={expDate}
                onChange={(e) => {
                  setExpDate(e.target.value);
                }}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3"
                name="title"
                placeholder="Select Expiry Date"
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please input medicine quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Medicine quantity" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Price/quantiry"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please input price per quantity!",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter price per quantity" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Dosage/Miligram(MG)"
                name="dosage"
                rules={[{ required: true, message: "Please input dosage!" }]}
              >
                <Input placeholder="Enter with MG" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <button
                id="addStaffBtn"
                className=" bg-sky-500 hover:bg-sky-600 text-white border shadow py-3 px-6 font-semibold text-md rounded-md"
                type="submit"
                style={{ width: "200px" }}
              >
                Submit
              </button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateMedicine;
