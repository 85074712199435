import React, { useState, useEffect, createContext, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOrderStatus } from "../lib/helpers";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { Table, Space, Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import ActionAppointmentReferredByMe from "../components/doctor_modal/referred/actionReferredByMe";

export const appointmentContext = createContext();

const ViewReferralAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let contextValue;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentNumber, setAppointmentNumber] = useState("");
  const [department, setDepartment] = useState("");
  const [full_name, setFullName] = useState("");
  const [problem, setProblem] = useState("");
  const [report, setReport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  useEffect(() => {
    fetchReferredAppointmentByMe();
  }, []);

  const fetchReferredAppointmentByMe = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/getReferredAppointments`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get referred appointments:", e);
        toast.error("Failed to get referred appointments! Please try again", {
          position: "bottom-left",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleModal = (appointment) => {
    if (appointment && appointment._id) {
      setIsModalOpen(!isModalOpen);

      setAppointmentId(appointment._id);
      setAppointmentNumber(appointment.appointmentNumber);
      setDepartment(appointment.department);
      setFullName(appointment.fullname);
      setProblem(appointment.problem);
      setReport(appointment.reports);
    }
    setIsModalOpen(!isModalOpen);
  };
  contextValue = {
    appointmentId,
    appointmentNumber,
    department,
    full_name,
    problem,
    report,
  };

  const filterAppointments = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (appointment) =>
            appointment?.appointmentNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.patientId?.userID
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.fullname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            appointment?.mobile.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchReferredAppointmentByMe();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterAppointments(searchTerm);
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Department (To)",
      dataIndex: ["refer", "toDepartment", "department"],
      key: "department",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <span>{getOrderStatus(status)}</span>,
    },
    {
      title: "Appointment Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="button"
            onClick={() => toggleModal(record)}
            className="px-4 py-2 rounded-md bg-sky-500 text-white hover:bg-sky-600"
          >
            Click here
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <DashboardStatsGrid />

      <div class="container max-w-8xl mx-auto mt-8">
        <div class="mb-4">
          <h1 class="text-3xl font-bold decoration-gray-400">
            Referred Appointments By Me
          </h1>
          <div className="mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div class="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-70"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
              <appointmentContext.Provider value={contextValue}>
                <ActionAppointmentReferredByMe />
              </appointmentContext.Provider>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => toggleModal(null)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ViewReferralAppointment;
