export function getOrderStatus(status) {
  const statusText = status.replaceAll("_", " ");
  let spanClass;

  switch (status) {
    case "Pending":
      spanClass = "text-red-600 bg-red-100";
      break;
    case "Ongoing":
      spanClass = "text-green-600 bg-green-100";
      break;
    case "FollowUp":
      spanClass = "text-blue-600 bg-blue-100";
      break;
    case "Cancelled":
      spanClass = "text-white bg-red-600";
      break;
    case "Cancel In Pending":
      spanClass = "text-rose-600 bg-rose-100";
      break;
    case "Ended":
      spanClass = "text-orange-600 bg-orange-100";
      break;
    case "Referred":
      spanClass = "text-gray-700 bg-gray-200";
      break;
    default:
      spanClass = "text-gray-600 bg-gray-100";
  }

  return (
    <div
      className={`capitalize py-1 px-1 rounded-md text-center text-sm ${spanClass}`}
    >
      {statusText}
    </div>
  );
}
