import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { message, Select } from "antd";

const { Option } = Select;

const UpdatePrescription = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { prescriptionId } = useParams();
  const navigate = useNavigate();
  const [followUp, setFollowUp] = useState("");
  const [medicineList, setMedicineList] = useState([]);
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [doctorDepartment, setDoctorDepartment] = useState("");
  const [medicineStoringValue, setMedicineStoringValue] = useState("");

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  const doctorConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  useEffect(() => {
    fetchPrescription();
    if (localStorage.getItem("doctorticket")) {
      fetchDoctorDepartment();
    }
    fetchMedicine();
  }, []);

  const fetchPrescription = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/get_gingle_prescription/${prescriptionId}`)
      .then((response) => {
        setDetails(response.data.data);
        setFollowUp(response.data.data.follow_up);
        setMedicineList(response.data.data.medicine);
      })
      .catch((e) => {
        console.error("Failed to get prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDoctorDepartment = () => {
    setLoading(true);

    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setDoctorDepartment(response.data.data.department.department);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  };

  const updatePrescription = async (e) => {
    setActionLoading(true);
    e.preventDefault();

    // console.log(medicineList);

    const data = {
      medicine: medicineList,
      follow_up: followUp,
    };

    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.put(
          `${apiBaseUrl}/doctor/prescription/update/${prescriptionId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.put(
          `${apiBaseUrl}/staff/prescription/update/${prescriptionId}`,
          data,
          config
        );
      }
      if (response.data.success) {
        message.success("Prescription Updated", 0.6, function onClose() {
          navigate(-1);
        });
      } else {
        message.error("Failed To Update! Please try again.");
      }
    } catch (error) {
      console.error("Error catched..", error);
      message.error("Failed to update prescription! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };
  const addMedicine = () => {
    setMedicineList([
      ...medicineList,
      {
        medicineName: "",
        frequency: "",
        Meal: "",
        duration: "",
      },
    ]);
  };

  const removeMedicine = (index) => {
    const updatedList = [...medicineList];
    updatedList.splice(index, 1);
    setMedicineList(updatedList);
  };

  const handleInputChange = (index, key, value) => {
    const updatedList = [...medicineList];
    updatedList[index][key] = value;
    setMedicineList(updatedList);
  };
  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}

      <div className="flex items-center">
        <div class="block p-6 items-center bg-white border border-gray-200 rounded-lg shadow ">
          <p class="text-2xl mb-1 font-bold tracking-tight text-gray-800">
            Update Prescription
          </p>
          <div class="font-normal text-gray-700">
            <form
              className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
              onSubmit={updatePrescription}
            >
              {medicineList.map((medicine, index) => (
                <div class="flex flex-wrap -mx-3 mb-2" key={index}>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-city"
                    >
                      Medicine Name
                    </label>
                    {doctorDepartment === "RHEUMATOLOGY" ? (
                      <Select
                        // style={{
                        //   border: "1px solid #d9d9d9",
                        //   borderRadius: "2px",
                        // }}
                        placeholder="Select a medicine antd-select-custom-style"
                        showSearch
                        className="w-full h-10"
                        allowClear
                        value={medicine.medicineName || undefined}
                        onChange={(value) => {
                          console.log("Selected value:", value);
                          if (value) {
                            handleInputChange(index, "medicineName", value);
                          } else {
                            handleInputChange(index, "medicineName", "");
                          }
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {medicineOptions.map((med, index) => (
                          // <Option key={index} value={med._id}>
                          <Option key={index} value={med.name}>
                            {med.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <input
                        class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                        type="text"
                        placeholder="Medicine name / dose"
                        value={medicine.medicineName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "medicineName",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-state"
                    >
                      Frequency
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. 2 Times a day"
                      value={medicine.frequency}
                      onChange={(e) =>
                        handleInputChange(index, "frequency", e.target.value)
                      }
                    />
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-state"
                    >
                      Meal (After or before)
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. After meal"
                      value={medicine.Meal}
                      onChange={(e) =>
                        handleInputChange(index, "Meal", e.target.value)
                      }
                    />
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-zip"
                    >
                      Duration
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. 2 weeks"
                      value={medicine.duration}
                      onChange={(e) =>
                        handleInputChange(index, "duration", e.target.value)
                      }
                    />
                  </div>
                  <IconButton
                    onClick={() => removeMedicine(index)}
                    color="danger"
                    aria-label="minus button"
                  >
                    <AiFillMinusCircle />
                  </IconButton>
                </div>
              ))}
              <IconButton
                onClick={addMedicine}
                color="primary"
                aria-label="add button"
              >
                Add medicine field
                <HiPlusCircle />
              </IconButton>
              <div>
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Follow Up
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setFollowUp(e.target.value);
                  }}
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                  name="title"
                  placeholder="Enter follow up date"
                  value={followUp}
                />
              </div>

              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default UpdatePrescription;
