import React, { useState } from "react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineLocalHospital } from "react-icons/md";
import { HiOutlineLogout } from "react-icons/hi";
import {
  DASHBOARD_SIDEBAR_LINKS,
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
  DASHBOARD_APPOINTMENT_SIDEBAR_LINKS,
  STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS,
} from "../../lib/constants";

const linkClass =
  "flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base";

// Modal component
const Modal = ({ title, message, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-gray-900 opacity-55 cursor-pointer"
        onClick={onClose}
      ></div>
      <div className="bg-white w-96 p-5 rounded-lg shadow-lg z-50">
        <div className="text-lg font-semibold mb-4">{title}</div>
        <div className="mb-4">{message}</div>
        <div className="flex justify-end">
          <button
            className="bg-gray-200 px-4 py-2 rounded-md mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default function Sidebar() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMenuAvailable = DASHBOARD_APPOINTMENT_SIDEBAR_LINKS;
  const isStaffMenuAvailable = STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS;

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="bg-neutral-900 w-60 p-3 flex flex-col relative">
      <div className="flex items-center gap-2 px-1 py-3">
        <MdOutlineLocalHospital fontSize={24} color="white" />
        <span className="text-neutral-200 text-lg">Tulsi Multi Clinic</span>
      </div>
      <div className="py-8 flex flex-1 flex-col gap-0.5">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}

        {isMenuAvailable ? (
          <>
            <div className="flex items-center gap-2 px-3 py-3">
              <MdOutlineLocalHospital fontSize={21} color="grey" />
              <span className="text-neutral-300 text-md">Appointments</span>
            </div>
            <div className="ms-5">
              {DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.map((link) => (
                <SidebarLink key={link.key} link={link} />
              ))}
            </div>
          </>
        ) : isStaffMenuAvailable ? (
          <>
            <div className="flex items-center gap-2 px-3 py-3">
              <MdOutlineLocalHospital fontSize={21} color="grey" />
              <span className="text-neutral-300 text-md">Appointments</span>
            </div>
            <div className="ms-5">
              {STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.map((link) => (
                <SidebarLink key={link.key} link={link} />
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}
        <div
          onClick={handleLogout}
          className={classNames(linkClass, "cursor-pointer text-red-500")}
        >
          <span className="text-xl">
            <HiOutlineLogout />
          </span>
          Logout
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title="Logout"
          message="Are you sure you want to log out?"
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            localStorage.clear();
            navigate("/", { replace: true });
          }}
        />
      )}
    </div>
  );
}

function SidebarLink({ link }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={link.path}
      className={classNames(
        pathname === link.path
          ? "bg-neutral-700 text-gray-300"
          : "text-neutral-400",
        linkClass
      )}
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}
