import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import LoadingScreen from "../lib/constants/loadingScreen";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { useParams } from "react-router-dom";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const UpdateNewAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [problem, setProblem] = useState("");
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [patientSelectedContact, setPatientSelectedContact] = useState("");
  const [patientSelectedAge, setPatientSelectedAge] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState(null);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  const [doctorStoringValue, setDoctorStoringValue] = useState(null);
  const [dateSelectedId, setDateSelectedId] = useState("");
  const [dateStoringValue, setDateStoringValue] = useState(null);
  const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  const [timeSelectedId, setTimeSelectedId] = useState("");
  const [timeStoringValue, setTimeStoringValue] = useState(null);
  const [defaultDepartment, setDefaultDepartment] = useState({});
  const [defaultDoctor, setDefaultDoctor] = useState({});
  const [defaultDate, setDefaultDate] = useState({});
  const [defaultTime, setDefaultTime] = useState({});
  const [previousSelectedTime, setPreviousSelectedTime] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  // fetch appointment details by id
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setPatientSelectedName(response.data.data.fullname);
          setPatientSelectedContact(response.data.data.mobile);
          setPatientSelectedAge(response.data.data.age);
          setProblem(response.data.data.problem);
          setDefaultDepartment({
            value: response.data.data.department._id,
            label: response.data.data.department.department,
          });
          setDepartmentSelectedId(response.data.data.department._id);
          setDefaultDoctor({
            value: response.data.data.doctorId._id,
            label: response.data.data.doctorId.fullname,
          });
          setDoctorSelectedId(response.data.data.doctorId._id);
          setDefaultDate({
            value: response.data.data.date,
            label: response.data.data.date,
          });
          setDateSelectedId(response.data.data.date);
          setDefaultTime({
            value: response.data.data.time,
            label: response.data.data.time,
          });
          setTimeSelectedId(response.data.data.time);
          setPreviousSelectedTime(response.data.data.time);
        }
      })
      .catch((error) => {
        console.error("Failed to get appointment detail", error);
        message.error(
          "Failed to get selected appointment detail! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // fetching patient details
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewpatientsstaff`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((patient) => ({
            value: patient._id,
            label: patient.fullname,
            age: patient.age,
            contact: patient.phone,
          }));
          setPatientDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        message.error("Failed to get user details! Please try again.");
      });
  }, []);

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
      setDateStoringValue("");
      setTimeStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      setDateStoringValue("");
      setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  useEffect(() => {
    if (doctorSelectedId) {
      axios
        .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            console.log(response.data.data);
            const options = response.data.data.map((date) => ({
              value: date.date,
              label: date.date,
              time: date.time,
            }));
            setSelectedDateDetails(options);
          }
        });
    }
  }, [doctorSelectedId]);

  const handleDateInputChange = (selectedDate) => {
    if (selectedDate) {
      setDateStoringValue(selectedDate);
      setDateSelectedId(selectedDate.value);
      setTimeStoringValue("");
    }
  };

  // for reloading the data when date is selected
  useEffect(() => {
    if (dateSelectedId) {
      const selectedDate = dateSelectedDetails.find(
        (date) => date.value === dateSelectedId
      );
      if (selectedDate && selectedDate.value === defaultDate.value) {
        const updatedTimeOptions = [
          ...(selectedDate.time || []).map((time) => ({
            value: time,
            label: time,
          })),
          { value: previousSelectedTime, label: previousSelectedTime },
        ];
        setSelectedTimeDetails(updatedTimeOptions);
      } else if (selectedDate) {
        setSelectedTimeDetails(
          selectedDate.time.map((time) => ({ value: time, label: time }))
        );
      }
    }
  }, [dateSelectedId, dateSelectedDetails, defaultDate, previousSelectedTime]);

  const handleTimeInputChange = (selectedTime) => {
    if (selectedTime) {
      setTimeSelectedId(selectedTime.value);
      setTimeStoringValue(selectedTime);
      console.log(selectedTime);
    }
  };

  const createAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      fullname: patientSelectedName,
      age: patientSelectedAge,
      mobile: patientSelectedContact,
      problem,
      department: departmentSelectedId,
      doctorId: doctorSelectedId,
      date: dateSelectedId,
      time: timeSelectedId,
    };

    axios
      .put(
        `${apiBaseUrl}/staff/updateBookedDoctorAppointment/${appointmentId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Appointment Updated Successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/viewappointment");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error updating appointment:", err);
        message.error("Error updating appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <DashboardStatsGrid />
      <div>
        <form
          className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={createAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Update appointment</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Patient Name</label>
              <input
                onChange={(e) => {
                  setPatientSelectedName(e.target.value);
                }}
                value={patientSelectedName}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-lg text-gray-700"
                name="title"
                placeholder="Fullname"
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Contact</label>
                <input
                  value={patientSelectedContact}
                  onChange={(e) => {
                    setPatientSelectedContact(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Contact"
                  required
                />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <label className="text-base ms-1">Age</label>
                <input
                  onChange={(e) => {
                    setPatientSelectedAge(e.target.value);
                  }}
                  value={patientSelectedAge}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Age"
                  required
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Problem</label>
              <input
                onChange={(e) => {
                  setProblem(e.target.value);
                }}
                className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="Problem"
                value={problem}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentStoringValue !== null
                    ? departmentStoringValue
                    : defaultDepartment
                }
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={
                  doctorStoringValue !== null
                    ? doctorStoringValue
                    : defaultDoctor
                }
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Date</label>
              <Select
                options={dateSelectedDetails}
                value={
                  dateStoringValue !== null ? dateStoringValue : defaultDate
                }
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Time</label>
              <Select
                options={timeSelectedDetails}
                value={
                  timeStoringValue !== null ? timeStoringValue : defaultTime
                }
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateNewAppointmentStaff;
