import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { useParams } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const UpdateRefer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointment_id } = useParams();
  const [department, setDepartment] = useState(null);
  const [departmentName, setDepartmentName] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");
  const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  const [dateSelectedId, setDateSelectedId] = useState("");
  const [dateStoringValue, setDateStoringValue] = useState("");
  const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  const [timeSelectedId, setTimeSelectedId] = useState("");
  const [timeStoringValue, setTimeStoringValue] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/appointments/referred/${appointment_id}`,
          config
        );
        setDepartment(response.data.refer.toDepartment._id);
        setDepartmentName(response.data.refer.toDepartment.department);
      } catch (e) {
        console.error("Failed to get refer details", e);
        message.error("Failed to get refer details! Please try again");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (department) {
      setLoading(true);
      axios
        .get(`${apiBaseUrl}/doctor/category/${department}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [department]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      setDateStoringValue("");
      setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  useEffect(() => {
    if (doctorSelectedId) {
      axios
        .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((date) => ({
              value: date.date,
              label: date.date,
              time: date.time,
            }));
            setSelectedDateDetails(options);
          }
        });
    }
  }, [doctorSelectedId]);

  const handleDateInputChange = (selectedDate) => {
    if (selectedDate) {
      setDateStoringValue(selectedDate);
      setDateSelectedId(selectedDate.value);
      setSelectedTimeDetails(selectedDate.time);
      setTimeStoringValue("");
    }
  };

  // handle time for selected date:
  const timeDataOptions = timeSelectedDetails.map((time) => ({
    value: time,
    label: time,
  }));

  const handleTimeInputChange = (selectedTime) => {
    if (selectedTime) {
      setTimeStoringValue(selectedTime);
      setTimeSelectedId(selectedTime.value);
    }
  };

  const updateRefer = (e) => {
    setActionLoading(true);
    e.preventDefault();
    const data = {
      assigningDoctorId: doctorSelectedId,
      referred_appointmentDate: dateSelectedId,
      referred_appointmentTime: timeSelectedId,
    };

    axios
      .post(
        `${apiBaseUrl}/staff/appointment/assignDoctor/${appointment_id}`,
        data,
        config
      )
      .then((response) => {
        if (
          response.data.msg ===
          "Doctor assigned to the appointment successfully."
        ) {
          message.success(
            "Doctor assigned sucessfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/refer");
            }
          );
        } else {
          message.error("Failed To assign doctor! Please try again.");
        }
      })
      .catch((error) => {
        console.error("Failed to assign doctor:", error);
        message.error("Failed to assign doctor! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };
  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}
      <div>
        <DashboardStatsGrid />
      </div>
      <form
        className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
        onSubmit={updateRefer}
      >
        <div className="-my-3 text-start">
          <Title level={2}>Assign Doctor</Title>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label className="text-base">Referred FROM (Department)</label>
            <input
              value={departmentName}
              className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
              name="title"
              disabled
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label className="text-base">Refer to(Doctor)</label>
            {doctorSelectedDetails.length > 0 ? (
              <Select
                options={doctorSelectedDetails}
                value={doctorStoringValue}
                onChange={handleDoctorInputChange}
                placeholder="Select a doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            ) : (
              <div class="text-center m-4 text-red-500">
                <div role="status">
                  <p>No doctors available!</p>
                  <p>Please try again, or verify the presence of data.</p>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label className="text-base text-lg">Date</label>
            <Select
              options={dateSelectedDetails}
              value={dateStoringValue}
              onChange={handleDateInputChange}
              placeholder="Select date"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0.375rem",
                }),
              }}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <label className="text-base text-lg">Time</label>
            <Select
              options={timeDataOptions}
              value={timeStoringValue}
              onChange={handleTimeInputChange}
              placeholder="Select time"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0.375rem",
                }),
              }}
            />
          </Col>
        </Row>

        <button
          id="addStaffBtn"
          className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
          type="submit"
        >
          Submit
        </button>
      </form>
    </>
  );
};
export default UpdateRefer;
