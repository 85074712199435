import React from "react";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const ViewLabBillsPage = () => {
  const handleClick = () => {
    // <Navigate to="/dashboard" />;
  };
  return (
    <div>
      <div>
        <DashboardStatsGrid />
      </div>
      <h1 className="text-3xl font-bold decoration-gray-400 mt-5">
        Lab-Reports Billing Archive
      </h1>
      <div className="pe-4 pt-4 bg-white mt-3">
        <Result
          status="warning"
          title="Apologies for any inconvenience. This section is currently under development. We appreciate your patience."
          extra={
            <Link
              to={"/dashboard"}
              className="bg-blue-600 hover:bg-blue-700 hover:text-white text-white rounded-md px-3 py-1"
            >
              Go Home
            </Link>
          }
        />
      </div>
    </div>
  );
};

export default ViewLabBillsPage;
