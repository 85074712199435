import React, { useEffect, useState } from "react";

const MyPDFViewer = ({ url }) => {
  const [screenHeight, setScreenHeight] = useState(
    document.documentElement.clientHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(document.documentElement.clientHeight);
      console.log(screenHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <iframe
      src={url}
      title="PDF Viewer"
      width="100%"
      height={screenHeight - 40 + "px"}
      style={{ border: "none" }}
    ></iframe>
  );
};

export default MyPDFViewer;
