import React, { useState } from "react";
import axios from "axios";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { Form, Row, Col, Input, message, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";

const { Title } = Typography;

const ManagePasswordAdmin = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { userId } = useParams();
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  const resetPassword = async (values) => {
    try {
      setActionLoading(true);
      const data = {
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      };
      const response = await axios.put(
        `${apiBaseUrl}/admin/reset-password/${userId}`,
        data,
        config
      );
      if (response.data.success) {
        message.success("Password Updated", 0.6, function onClose() {
          navigate(-1);
        });
      } else {
        const errorMessage =
          response.data.error || "Failed to reset password! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to reset password:", error);
      const errorMessage_1 =
        error.response?.data?.error ||
        "Failed to reset password! Please try again.";
      message.error(errorMessage_1);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <DashboardStatsGrid />
      <Form
        form={form}
        layout="vertical"
        onFinish={resetPassword}
        className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Reset Password</Title>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                className="rounded-md"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="confirmNewPassword"
              label="Confirm New Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                className="rounded-md"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            id="addStaffBtn"
            className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
export default ManagePasswordAdmin;
