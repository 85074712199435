import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Select, message, Row, Col, Typography } from "antd";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../lib/constants/loadingScreen";

const { Option } = Select;
const { Title } = Typography;

const AddDoctor = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [details, setDetails] = useState([]);
  const [DOB, setDOB] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((result) => {
        setDetails(result.data.data);
      })
      .catch((e) => {
        console.error("Error fetching department", e);
        toast.error("Failed to get department details", {
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    setImageURL(URL.createObjectURL(file));
    form.setFieldsValue({ photos: file });
  };

  const registerDoctor = async (values) => {
    setActionLoading(true);
    try {
      const data = new FormData();
      data.append("fullname", values.fullname);
      data.append("gender", values.gender);
      data.append("DOB", DOB);
      data.append("email", values.email);
      data.append("phone", values.phone);
      data.append("address", values.address);
      data.append("consultFee", values.consultFee);
      data.append("department", values.department);
      data.append("photo", values.photos);

      const response = await axios.post(
        `${apiBaseUrl}/doctor/register`,
        data,
        config
      );

      if (response.data.success) {
        message.success("Doctor added successfully");
        window.location.replace("/dashboard/viewdoctor");
      } else {
        const errorMessage =
          response.data.error ||
          "An error occurred while registering the doctor.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to register doctor:", error);
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "An unexpected error occurred while registering the doctor.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading || loading ? (
        <LoadingScreen />
      ) : (
        <>
          <DashboardStatsGrid />
          <Form
            form={form}
            onFinish={registerDoctor}
            layout="vertical"
            className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          >
            <Title level={2}>Add Doctor</Title>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Fullname"
                  name="fullname"
                  rules={[
                    { required: true, message: "Please enter full name" },
                  ]}
                >
                  <Input placeholder="Enter full name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    { required: true, message: "Please enter phone number" },
                  ]}
                >
                  <Input placeholder="Enter phone number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                    { required: true, message: "Please enter email address" },
                  ]}
                >
                  <Input placeholder="Enter email address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="DOB"
                  name="DOB"
                  rules={[
                    { required: false, message: "Please enter email address" },
                  ]}
                >
                  <input
                    id="DOB"
                    value={DOB}
                    type="date"
                    onChange={(e) => {
                      setDOB(e.target.value);
                    }}
                    max={new Date().toISOString().split("T")[0]}
                    className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                    name="dob"
                    placeholder="Select Date"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: "Please enter address" }]}
                >
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: "Please select gender" }]}
                >
                  <Select placeholder="Select gender">
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Others">Others</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Doctor Department"
                  name="department"
                  rules={[
                    { required: true, message: "Please select department" },
                  ]}
                >
                  <Select placeholder="Select department">
                    {details.map((department, index) => (
                      <Option key={department._id} value={department._id}>
                        {department.department}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Consult Fee"
                  name="consultFee"
                  rules={[
                    { required: true, message: "Please enter consult fee" },
                  ]}
                >
                  <Input type="number" placeholder="Enter consult fee" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Image"
              name="photos"
              rules={[{ required: true, message: "Please upload an image" }]}
            >
              <input
                onChange={handlePictureChange}
                type="file"
                className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
              />
              {imageURL && (
                <div>
                  <img
                    src={imageURL}
                    alt="Doctor Preview"
                    className="mt-4 rounded-lg max-h-40"
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item>
              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit" //htmlType
              >
                Submit
              </button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default AddDoctor;
