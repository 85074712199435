import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { appointmentContext } from "./ViewAppointments";
import LoadingScreen from "../lib/constants/loadingScreen";

const ActionAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    full_name,
    problem,
    status,
    isDoctorAssignedAvailable,
  } = useContext(appointmentContext);
  let appointment_status;
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  const updateAppointmentStatus = () => {
    appointment_status = "Cancelled";
    if (
      window.confirm("Are you sure want to change this appointment status?")
    ) {
      setActionLoading(true);
      const data = {
        appointmentStatus: appointment_status,
      };

      axios
        .put(
          `${apiBaseUrl}/staff/updateAppointmentStatus/` + appointmentId,
          data,
          config
        )
        .then((response) => {
          if (response.data.msg == "Updated Status") {
            toast.success("Appointment Cancelled", {
              position: "bottom-right",
            });

            window.location.reload();
          } else {
            toast.failed("Failed To Update", {
              position: "bottom-right",
            });
          }
        })
        .catch((err) => {
          console.error("Error updating appointment status:", err);
          toast.error(
            "Failed to update appointment status! Please try again.",
            {
              position: "bottom-right",
            }
          );
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:text-left">
            <h1
              className="text-3xl leading-6 font-medium text-center text-gray-900"
              id="modal-title"
            >
              Actions
            </h1>
            <div className="">
              <p className="text-sm text-gray-500 mx-5">
                <div class="py-2 mx-5">
                  <ul class="my-4 space-y-3 mx-8">
                    {status == "Ongoing" ? (
                      <>
                        <li>
                          <Link
                            to={`/dashboard/update/new/appointment/${appointmentId}`}
                            state={{ full_name, problem }}
                            className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span class="flex-1 whitespace-nowrap">
                              Update Appointment
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={updateAppointmentStatus}
                            className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                          >
                            <span class="flex-1 whitespace-nowrap">
                              Cancel Appointment
                            </span>
                          </Link>
                        </li>
                        <div className="text-center">
                          <p className="text-center">
                            For other actions, please redirect to:
                          </p>
                          <Link
                            to={"/dashboard/view/inprogress/appointment"}
                            className="text-sky-500 underline"
                          >
                            InProgress section
                          </Link>
                        </div>
                      </>
                    ) : status == "FollowUp" ? (
                      <>
                        {!isDoctorAssignedAvailable ? (
                          <>
                            <li>
                              <Link
                                to={`/dashboard/staff/update/datetime/appointment/${appointmentId}`}
                                state={{ full_name, problem, status }}
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span class="flex-1 whitespace-nowrap">
                                  Edit FollowUp DateTime
                                </span>
                              </Link>
                            </li>

                            <div className="text-center">
                              <p className="text-center">
                                For other actions, please redirect to:
                              </p>
                              <Link
                                to={"/dashboard/view/inprogress/appointment"}
                                className="text-sky-500 underline"
                              >
                                InProgress section
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center">
                              <p className="text-center">
                                Please assign a doctor first, Redirect to:
                              </p>
                              <Link
                                to={"/dashboard/refer"}
                                className="text-sky-500 underline"
                              >
                                Refer Section
                              </Link>
                            </div>
                          </>
                        )}
                      </>
                    ) : status == "Referred" ? (
                      <>
                        {!isDoctorAssignedAvailable ? (
                          <>
                            <li>
                              <Link
                                to={`/dashboard/staff/update/datetime/appointment/${appointmentId}`}
                                state={{ full_name, problem, status }}
                                className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                              >
                                <span class="flex-1 whitespace-nowrap">
                                  Edit Refer DateTime
                                </span>
                              </Link>
                            </li>

                            <div className="text-center">
                              <p className="text-center">
                                For other actions, please redirect to:
                              </p>
                              <Link
                                to={"/dashboard/view/referred/appointment"}
                                className="text-sky-500 underline"
                              >
                                Referred section
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-center">
                              <p className="text-center">
                                Please assign a doctor first, Redirect to:
                              </p>
                              <Link
                                to={"/dashboard/refer"}
                                className="text-sky-500 underline"
                              >
                                Refer Section
                              </Link>
                            </div>
                          </>
                        )}
                      </>
                    ) : status == "Ended" ? (
                      <>
                        <div className="text-center">
                          <p className="text-center">
                            For ended actions, please redirect to:
                          </p>
                          <Link
                            to={"/dashboard/view/cancelstatus/appointment"}
                            className="text-sky-500 underline"
                          >
                            Completed section
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-center">
                          <p className="text-center">
                            For cancelled actions, please redirect to:
                          </p>
                          <Link
                            to={"/dashboard/view/completed/appointment"}
                            className="text-sky-500 underline"
                          >
                            Cancel Status section
                          </Link>
                        </div>
                      </>
                    )}
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionAppointmentStaff;
