import React from "react";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import TransactionChart from "../components/TransactionChart";
import RecentOrders from "../components/RecentOrders";
import BuyerProfilePieChart from "../components/BuyerProfilePieChart";

export default function Dashboard() {
  if (localStorage.getItem("adminticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <DashboardStatsGrid />
        <div className="flex flex-row gap-4 w-full">
          <TransactionChart />
          <BuyerProfilePieChart />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <RecentOrders />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("doctorticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <DashboardStatsGrid />
        <div className="flex flex-row gap-4 w-full">
          <TransactionChart />
          <BuyerProfilePieChart />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <RecentOrders />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("staffticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <DashboardStatsGrid />
        <div className="flex flex-row gap-4 w-full">
          <TransactionChart />
          <BuyerProfilePieChart />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <RecentOrders />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("labreporterticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <DashboardStatsGrid />
        <div className="flex flex-row gap-4 w-full">
          <TransactionChart />
          <BuyerProfilePieChart />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <RecentOrders />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("pharmaticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <DashboardStatsGrid />
        <div className="flex flex-row gap-4 w-full">
          <TransactionChart />
          <BuyerProfilePieChart />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <RecentOrders />
        </div>
      </div>
    );
  } else {
    window.location.replace("/");
  }
}
