import {
  HiOutlineViewGrid,
  HiBadgeCheck,
  HiOutlineCog,
  HiOutlineFolderAdd,
} from "react-icons/hi";
import {
  FaUserDoctor,
  FaHospitalUser,
  FaClipboardCheck,
  FaClipboardList,
  FaRegCircleUser,
  FaMoneyBillTransfer,
  FaNotesMedical,
  FaFileMedical,
} from "react-icons/fa6";
import {
  TbLayoutSidebarLeftExpandFilled,
  TbLayoutSidebarRightExpandFilled,
  TbReportAnalytics,
  TbCalendarRepeat,
  TbBoxMultiple,
} from "react-icons/tb";
import { MdFindInPage, MdFreeCancellation, MdSick } from "react-icons/md";
import { FaUserTie, FaUserTag, FaUsers } from "react-icons/fa";
import { PiCircleNotchFill } from "react-icons/pi";
import { GiMedicines } from "react-icons/gi";
import { SlCalender } from "react-icons/sl";
import { GiCycle } from "react-icons/gi";

var menu;
if (localStorage.getItem("adminticket")) {
  menu = [
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <HiOutlineViewGrid />,
    },
    {
      key: "department",
      label: "Department",
      path: "/dashboard/department",
      icon: <TbBoxMultiple />,
    },
    {
      key: "symptoms",
      label: "Symptoms",
      path: "/dashboard/symptoms",
      icon: <MdSick />,
    },
    {
      key: "medicine",
      label: "Medicines",
      path: "/dashboard/view/medicine",
      icon: <GiMedicines />,
    },
    {
      key: "doctor",
      label: "Doctors",
      path: "/dashboard/viewdoctor",
      icon: <FaUserDoctor />,
    },
    {
      key: "addstaff",
      label: "Staff",
      path: "/dashboard/viewstaff",
      icon: <FaUserTie />,
    },
    {
      key: "viewlabreporter",
      label: "Lab Reporter",
      path: "/dashboard/viewlabreporter",
      icon: <FaUserTag />,
    },
    {
      key: "viewpharmastaff",
      label: "Pharma Staff",
      path: "/dashboard/viewpharmastaff",
      icon: <FaHospitalUser />,
    },
  ];
}
if (localStorage.getItem("doctorticket")) {
  menu = [
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <HiOutlineViewGrid />,
    },
  ];
}
if (localStorage.getItem("staffticket")) {
  menu = [
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <HiOutlineViewGrid />,
    },
    {
      key: "users",
      label: "Users",
      path: "/dashboard/viewuser",
      icon: <FaUsers />,
    },
    {
      key: "lab-bills",
      label: "Create Lab Bills",
      path: "/dashboard/get/generated/lab-bills",
      icon: <FaFileMedical />,
    },
  ];
}

if (localStorage.getItem("labreporterticket")) {
  menu = [
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <HiOutlineViewGrid />,
    },
    {
      key: "View Appointments",
      label: "Appointment",
      path: "/dashboard/viewappointment",
      icon: <HiOutlineFolderAdd />,
    },
    {
      key: "View Appointments",
      label: "Reports",
      path: "/dashboard/view/labreports",
      icon: <TbReportAnalytics />,
    },
  ];
}

if (localStorage.getItem("pharmaticket")) {
  menu = [
    {
      key: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <HiOutlineViewGrid />,
    },
    {
      key: "medicine",
      label: "Medicine",
      path: "/dashboard/medicine",
      icon: <GiMedicines />,
    },
    {
      key: "bill",
      label: "Billing",
      path: "/dashboard/bills",
      icon: <FaClipboardList />,
    },
  ];
}

export const DASHBOARD_SIDEBAR_LINKS = menu;

var appointment_menu;
if (localStorage.getItem("doctorticket")) {
  appointment_menu = [
    {
      key: "ongoing",
      label: "Ongoing",
      path: "/dashboard/viewOngoingAppointmentbyDoctor",
      icon: <PiCircleNotchFill />,
    },
    {
      key: "followup",
      label: "Follow Up",
      path: "/dashboard/view/followup/appointments",
      icon: <TbCalendarRepeat />,
    },
    {
      key: "referredtome",
      label: "Referred To me",
      path: "/dashboard/viewReferredAppointment/toDoctor",
      icon: <TbLayoutSidebarRightExpandFilled />,
    },

    {
      key: "referredbyme",
      label: "Referred from me",
      path: "/dashboard/viewReferredAppointmentbyDoctor",
      icon: <TbLayoutSidebarLeftExpandFilled />,
    },
    {
      key: "completed",
      label: "Completed",
      path: "/dashboard/viewEndedAppointmentbyDoctor",
      icon: <FaClipboardCheck />,
    },
  ];
}

export const DASHBOARD_APPOINTMENT_SIDEBAR_LINKS = appointment_menu;

var staff_appointment_menu;
if (localStorage.getItem("staffticket")) {
  staff_appointment_menu = [
    {
      key: "addAppointment",
      label: "All Appointments",
      path: "/dashboard/viewappointment",
      icon: <FaNotesMedical />,
    },
    // {
    //   key: "addAppointment",
    //   label: "Add Appointment",
    //   path: "/dashboard/add/new/appointment",
    //   icon: <BiSolidMessageSquareAdd />,
    // },
    {
      key: "inProgress",
      label: "In progress",
      path: "/dashboard/view/inprogress/appointment",
      icon: <GiCycle />,
    },
    {
      key: "referred",
      label: "Referred",
      path: "/dashboard/view/referred/appointment",
      icon: <FaMoneyBillTransfer />,
    },
    {
      key: "cancelStatus",
      label: "Cancel Status",
      path: "/dashboard/view/cancelstatus/appointment",
      icon: <MdFreeCancellation />,
    },
    {
      key: "completed",
      label: "Completed",
      path: "/dashboard/view/completed/appointment",
      icon: <HiBadgeCheck />,
    },

    {
      key: "appointment_times",
      label: "Appointment Times",
      path: "/dashboard/view_appointment/datetime",
      icon: <SlCalender />,
    },
    {
      key: "refer",
      label: "Check Refers",
      path: "/dashboard/refer",
      icon: <MdFindInPage />,
    },
  ];
}

export const STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS = staff_appointment_menu;

let DASHBOARD_SIDEBAR_BOTTOM_LINKS_CONDITIONAL;

if (localStorage.getItem("adminticket")) {
  DASHBOARD_SIDEBAR_BOTTOM_LINKS_CONDITIONAL = [];
} else {
  DASHBOARD_SIDEBAR_BOTTOM_LINKS_CONDITIONAL = [
    {
      key: "profile",
      label: "Profile",
      path: "/dashboard/profile",
      icon: <FaRegCircleUser />,
    },
    {
      key: "changePassword",
      label: "Change Password",
      path: "/dashboard/changepassword",
      icon: <HiOutlineCog />,
    },
    // {
    // 	key: 'support',
    // 	label: 'Help & Support',
    // 	path: '/support',
    // 	icon: <HiOutlineQuestionMarkCircle />
    // }
  ];
}

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS =
  DASHBOARD_SIDEBAR_BOTTOM_LINKS_CONDITIONAL;
