import react, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingScreen from "../lib/constants/loadingScreen";

const ViewPrescription = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { full_name, problem } = location.state || {};
  const [followUp, setFollowUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineList, setMedicineList] = useState([
    {
      medicineName: "",
      frequency: "",
      Meal: "",
      duration: "",
    },
  ]);
  const [details, setDetails] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  const handleActionLoading = (value) => {
    setActionLoading(value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/get_prescription/${appointmentId}`)
      .then((response) => {
        setDetails(response.data.data);
        console.log(response.data.data);
      })
      .catch((e) => {
        console.error("Failed to fetch prescription details:", e);
        toast.error("Failed to get prescription details! Please try again.", {
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addPrescription = async (e) => {
    e.preventDefault();
    handleActionLoading(true);
    const data = {
      medicine: medicineList,
      follow_up: followUp,
    };
    try {
      const response = await axios.post(
        `${apiBaseUrl}/doctor/prescription/create/${appointmentId}`,
        data,
        config
      );
      if (response.data.success == true) {
        toast.success("Prescription Added", {
          position: "bottom-right",
        });
        window.location.reload();
      } else {
        toast.error("Failed To add prescription", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error("Failed to add prescription:", error);
      toast.error("Failed To add prescription! Please try again.", {
        position: "bottom-right",
      });
    } finally {
      handleActionLoading(false);
    }
  };
  const addMedicine = () => {
    setMedicineList([
      ...medicineList,
      {
        medicineName: "",
        frequency: "",
        Meal: "",
        duration: "",
      },
    ]);
  };

  const removeMedicine = (index) => {
    const updatedList = [...medicineList];
    updatedList.splice(index, 1);
    setMedicineList(updatedList);
  };

  const handleInputChange = (index, key, value) => {
    const updatedList = [...medicineList];
    updatedList[index][key] = value;
    setMedicineList(updatedList);
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const deletePrescription = async (
    prescription_id,
    appointmentId,
    full_name,
    problem
  ) => {
    if (window.confirm("Are you sure want to delete this prescription?")) {
      handleActionLoading(true);
      try {
        const result = await axios.delete(
          `${apiBaseUrl}/doctor/delete_prescription/${prescription_id}`,
          config
        );
        if (result.data.success == true) {
          toast.success("Prescription Deleted", {
            position: "bottom-right",
          });
          navigate(
            `/dashboard/viewOngoingAppointmentbyDoctor/add_prescription/${appointmentId}`,
            { state: { full_name, problem } }
          );
          window.location.reload();
        } else {
          toast.error("Prescription Not Deleted", {
            position: "bottom-right",
          });
        }
      } catch (e) {
        console.error("Failed to delete prescription:", e);
        toast.error("Failed to delete prescription! Please try again.", {
          position: "bottom-right",
        });
      } finally {
        handleActionLoading(false);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="p-3 mb-5">
        <h1 className="text-3xl font-bold decoration-gray-400">Prescription</h1>
      </div>

      <div className="flex items-start">
        <div
          class="me-2 p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow 
              lg:max-w-[50%] lg:w-[50%]"
        >
          {loading && (
            <>
              <div>
                <LoadingScreen />
              </div>
            </>
          )}

          {!loading && (
            <div className="">
              <p>
                <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold">{` ${full_name}`}</span>
                </h5>
              </p>
              <p class="mb-1 font-semibold text-gray-700">
                Problem: <span className="font-normal">{` ${problem}`}</span>
              </p>
              {details.length > 0 ? (
                details.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="me-2 mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex-grow"
                    >
                      <p class="mb-1 font-semibold text-gray-700">
                        Prescription added on:{" "}
                        <span className="font-normal">
                          {formatDate(item.createdAt)}
                        </span>
                      </p>
                      <p class="mb-1 font-semibold text-gray-700">
                        Follow Up:{" "}
                        <span className="font-normal">{item.follow_up}</span>
                      </p>
                      <span class="mb-1 font-semibold text-gray-700">
                        Medicines:
                        <ul style={{ listStyleType: "disc" }} className="ps-4">
                          {item.medicine.map((medicineItem) => {
                            return (
                              <li className="font-normal">
                                <span className="font-medium">Medicine: </span>
                                <span>{medicineItem.medicineName}</span>
                                <span className="font-medium">
                                  {" || "}Frequency:{" "}
                                </span>
                                <span> {medicineItem.frequency}</span> <br />
                                <span className="font-medium">Duration: </span>
                                <span>{medicineItem.duration}</span>
                                <span className="font-medium">
                                  {" || "}Meal:{" "}
                                </span>
                                <span>{medicineItem.Meal}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </span>

                      <div class="flex justify-start mt-2">
                        <Link
                          to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                          state={{ appointmentId: appointmentId }}
                        >
                          <button class="px-4 py-1 me-5 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700">
                            Update
                          </button>
                        </Link>
                        <Link
                          onClick={() => {
                            deletePrescription(
                              item._id,
                              appointmentId,
                              full_name,
                              problem
                            );
                          }}
                        >
                          <button class="px-4 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                            Delete
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <p className="mt-3">
                    <h5 class="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                      Prescription not found!!
                    </h5>
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ViewPrescription;
