import React, { useState } from "react";
import axios from "axios";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Form, Input, Select, message, Row, Col, Typography } from "antd";

const { Option } = Select;
const { Title } = Typography;

const AddPatient = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [DOB, setDOB] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const registerPatient = async (values) => {
    setActionLoading(true);

    const data = {
      fullname: values.fullname,
      phone: values.phone,
      address: values.address,
      gender: values.gender,
    };

    if (DOB !== "") {
      data.DOB = DOB;
    }
    if (values.age !== "") {
      data.age = values.age;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}/patient/insert`, data);
      if (response.data.success) {
        message.success("Patient added successfully", 0.6, function onClose() {
          window.location.replace("/dashboard/viewuser");
        });
      } else {
        const errorMessage =
          response.data.error ||
          "An error occurred while registering the patient.";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error("Failed to register staff:", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred while registering the staff.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <DashboardStatsGrid />
      <Form
        form={form}
        onFinish={registerPatient}
        layout="vertical"
        className="forbox w-full max-w-3xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Add Patient</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Fullname"
              name="fullname"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Please enter phone number" }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="DOB"
              rules={[
                {
                  message: "Please enter a valid email address",
                },
                { required: false, message: "Please enter email address" },
              ]}
            >
              <input
                id="DOB"
                value={DOB}
                type="date"
                onChange={(e) => {
                  setDOB(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Age (Optional)"
              name="age"
              rules={[{ required: false, message: "Please enter age" }]}
            >
              <Input placeholder="Enter age (No need if DOB)" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddPatient;
