import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ChangeReferredDepartmentByStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();

  let location = useLocation();
  const {
    full_name,
    problem,
    doctorCheckIfInReferred,
    departmentCheckIfInReferred,
    departmentIsSelectedCheckIfInReferred,
  } = location.state;

  const [actionLoading, setActionLoading] = useState(false);
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentToSelectedId, setDepartmentToSelectedId] = useState("");
  const [departmentToStoringValue, setDepartmentToStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
          //   setDepartmentToSelectedId(
          //     departmentIsSelectedCheckIfInReferred.value
          //   );
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        toast.error("Failed to get department details! Please try again.", {
          position: "bottom-right",
        });
      });
  }, []);

  const handleDepartmentToInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentToStoringValue(selectedDepartment);
      setDepartmentToSelectedId(selectedDepartment.value);
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const referDepartment = async (e) => {
    e.preventDefault();

    const data = {
      assigningDepartment: departmentToSelectedId,
    };

    try {
      if (window.confirm("Are you sure want to refer this appointment?")) {
        setActionLoading(true);
        const response = await axios.post(
          `${apiBaseUrl}/staff/appointment/referDepartment/${appointmentId}`,
          data,
          config
        );
        if (response.data.msg == "Department assigned") {
          toast.success("Patient refered success", {
            position: "bottom-right",
          });
          window.location.replace("/dashboard/view/referred/appointment");
        } else if (departmentToSelectedId == "") {
          toast.error(
            "Same department Selected! Please change department or back to referred page.",
            {
              position: "bottom-right",
            }
          );
        } else {
          toast.error("Failed to refer department! Please try again.", {
            position: "bottom-right",
          });
        }
      }
    } catch (err) {
      console.error("Failed to refer department: ", err);
      toast.error("Failed to refer department! Please try again.", {
        position: "bottom-right",
      });
    } finally {
      setActionLoading(false);
    }
  };
  return (
    <>
      <div className="p-3 mb-5">
        <h1 className="text-3xl font-bold decoration-gray-400">
          Change Refer-Department
        </h1>
      </div>

      <div
        href="#"
        class="block max-w-sm p-6 items-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100"
      >
        <p class="text-xl mb-1 font-bold tracking-tight text-gray-600">
          Patient Name:{" "}
          <span className="text-gray-600 text-l font-medium">{full_name}</span>
        </p>
        <p class="text-xl mb-1 font-bold tracking-tight text-gray-600">
          Problem:{" "}
          <span className="text-gray-600 text-l font-medium">{problem}</span>
        </p>
        <div class="font-normal text-gray-700">
          <form
            className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
            onSubmit={referDepartment}
          >
            <div>
              <label className="text-gray-600 font-medium">
                Department(FROM)
              </label>
              <input
                id="from_doctor"
                type="text"
                value={departmentCheckIfInReferred}
                disabled
                className="border-solid border-gray-300 border h-14 py-2 px-4 w-full rounded-lg text-gray-700"
                name="title"
                required
              />
            </div>
            <div>
              <label className="text-gray-600 font-medium">Doctor(FROM)</label>
              <input
                id="from_doctor"
                type="text"
                value={doctorCheckIfInReferred}
                disabled
                className="border-solid border-gray-300 border h-14 py-2 px-4 w-full rounded-lg text-gray-700"
                name="title"
                required
              />
            </div>

            <div>
              <label className="text-gray-600 font-medium text-lg">
                Department (REFER TO)
              </label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentToStoringValue
                    ? departmentToStoringValue
                    : departmentIsSelectedCheckIfInReferred
                }
                onChange={handleDepartmentToInputChange}
                isSearchable
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: "1px solid #ccc",
                    height: "3.5rem",
                    padding: "0.2rem 0.5em",
                    width: "100%",
                    borderRadius: "0.375rem",
                    color: "#4B5563",
                  }),
                }}
              />
            </div>

            <button
              className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-blue-100 border shadow py-3 px-6 font-semibold text-md rounded"
              type="submit"
            >
              Submit
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangeReferredDepartmentByStaff;
