const DataUnavailableScreen = () => {
  return (
    <>
      <div className="text-center m-4">
        <div role="status">
          <p>No data available!</p>
          <p>Please try again, or verify the presence of data.</p>
        </div>
      </div>
    </>
  );
};

export default DataUnavailableScreen;
