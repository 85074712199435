import React, { PureComponent } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "January",
    patients: 800,
    appointments: 1400,
  },
  {
    name: "February",
    patients: 967,
    appointments: 1506,
  },
  {
    name: "March",
    patients: 1098,
    appointments: 989,
  },
  {
    name: "April",
    patients: 1200,
    appointments: 1228,
  },
  {
    name: "May",
    patients: 1108,
    appointments: 1100,
  },
  {
    name: "June",
    patients: 680,
    appointments: 1700,
  },
  {
    name: "July",
    patients: 680,
    appointments: 1700,
  },
];

export default class TransactionChart extends PureComponent {
  static demoUrl =
    "https://codesandbox.io/s/composed-chart-in-responsive-container-pkqmy";

  render() {
    return (
      <div className="h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1">
        <strong className="text-gray-700 font-medium">
          Users/Appointments
        </strong>
        <div className="mt-3 w-full flex-1 text-xs">
          <ResponsiveContainer>
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="appointments"
                fill="#8884d8"
                stroke="#8884d8"
              />
              <Bar dataKey="patients" barSize={20} fill="#413ea0" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
