import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button
              onClick={handleButtonClick}
              className="bg-blue-500"
              type="primary"
            >
              Back Home
            </Button>
          }
        />
      </div>
    </>
  );
};
export default UnauthorizedPage;
