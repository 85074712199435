import React from "react";
import { Link } from "react-router-dom";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { useLocation } from "react-router-dom";
import DataUnavailableScreen from "../lib/constants/dataUnavailable";

const ViewReportByAppointmentId = () => {
  const location = useLocation();
  const { report, appointmentNumber, full_name } = location.state;

  return (
    <>
      <DashboardStatsGrid />
      <div className="container max-w-8xl mx-auto mt-8">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Reports of {full_name}
          </h1>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Appointment Number
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                      Report Name
                    </th>
                    <th
                      className="px-6 py-3 text-sm text-center text-gray-500 border-b border-gray-200 bg-gray-50"
                      colSpan="3"
                    >
                      Links
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white">
                  {!report.length > 0 ? (
                    <tr>
                      <td colSpan={3} className="text-center py-8">
                        <DataUnavailableScreen />
                      </td>
                    </tr>
                  ) : (
                    report.map((singleData) => {
                      return (
                        <tr key={singleData._id}>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <p>{appointmentNumber}</p>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <p>{singleData.reportName}</p>
                          </td>
                          <td className="text-sm font-medium leading-5 text-center whitespace-no-wrap border-b border-gray-200 ">
                            <Link
                              to={"/dashboard/view_reportdr"}
                              state={{ report: singleData.report }}
                              className="px-4 py-2 rounded-md bg-sky-500 text-white hover:bg-sky-600"
                            >
                              Click here
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewReportByAppointmentId;
