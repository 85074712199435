import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { appointmentContext } from "../../../pages/EndedAppointment";
import { jwtDecode } from "jwt-decode";

const ActionEndedAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    full_name,
    problem,
    report,
    doctorIdForCheck,
  } = useContext(appointmentContext);

  const [appointment_status, setAppointmentStatus] = useState("FollowUp");
  const [actionLoading, setActionLoading] = useState(false);
  const [loggedInDoctorId, setLoggedInDoctorId] = useState(null);

  useEffect(() => {
    const token =
      localStorage.getItem("doctorticket") ||
      localStorage.getItem("staffticket");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLoggedInDoctorId(decodedToken.dID);
    }
  }, []);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  // set for future usecase:::

  // const updateAppointmentStatus = () => {
  //   if (loggedInDoctorId === doctorIdForCheck) {
  //     if (
  //       window.confirm(
  //         "Are you sure want to change this appointment status to follow up?"
  //       )
  //     ) {
  //       setActionLoading(true);
  //       const data = {
  //         appointmentStatus: appointment_status,
  //       };
  //       axios
  //         .put(
  //           `${apiBaseUrl}/doctor/updateAppointmentStatus/` +
  //             appointmentId,
  //           data,
  //           config
  //         )
  //         .then((response) => {
  //           if (response.data.msg == "Updated Status") {
  //             message.success(
  //               "Appointment status changed to followup.",
  //               0.6,
  //               function onClose() {
  //                 window.location.replace(
  //                   "/dashboard/view/followup/appointments"
  //                 );
  //               }
  //             );
  //           } else {
  //             message.failed("Failed To Update! Please try again.");
  //           }
  //         })
  //         .catch((err) => {
  //           console.error("Error", err);
  //           message.error("Failed to update! Please try again.");
  //         })
  //         .finally(() => {
  //           setActionLoading(false);
  //         });
  //     }
  //   } else {
  //     message.error(
  //       <>
  //         "Sorry! You are not authorized to change the status of this
  //         appointment! <br /> Please contact ADMIN or STAFF."
  //       </>
  //     );
  //   }
  // };

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center">
            <h1
              className="text-3xl leading-6 font-medium text-center text-gray-900"
              id="modal-title"
            >
              Actions
            </h1>
            <div className="">
              <p className="text-sm text-gray-500 mx-5">
                <div class="py-2 mx-5">
                  <ul class="my-4 space-y-3 mx-8">
                    <li>
                      <Link
                        to={`/dashboard/viewreport/${appointmentId}`}
                        state={{ report, appointmentNumber, full_name }}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span class="flex-1 whitespace-nowrap">
                          View Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/dashboard/viewAppointmentbyDoctor/view/prescription/${appointmentId}`}
                        state={{ full_name, problem }}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span class="flex-1 whitespace-nowrap">
                          View Prescription
                        </span>
                      </Link>
                    </li>
                    {/* <li>
                      <button
                        onClick={updateAppointmentStatus}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span class="flex-1 whitespace-nowrap">
                          Arrange Follow-Up
                        </span>
                      </button>
                    </li> */}
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionEndedAppointment;
