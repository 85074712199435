import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, roles }) => {
  const isAuthenticated =
    localStorage.getItem("adminticket") ||
    localStorage.getItem("doctorticket") ||
    localStorage.getItem("staffticket") ||
    localStorage.getItem("labreporterticket") ||
    localStorage.getItem("pharmaticket");

  const userRole =
    (localStorage.getItem("adminticket") && "admin") ||
    (localStorage.getItem("doctorticket") && "doctor") ||
    (localStorage.getItem("staffticket") && "staff") ||
    (localStorage.getItem("labreporterticket") && "labreporter") ||
    (localStorage.getItem("pharmaticket") && "pharma");

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!roles.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
