import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { message, Select } from "antd";

const { Option } = Select;

const AddPrescription = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { full_name, problem } = location.state || {};
  const [followUp, setFollowUp] = useState("");
  const [loading, setLoading] = useState(true);
  const [doctorDepartment, setDoctorDepartment] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicineList, setMedicineList] = useState([
    {
      medicineName: "",
      frequency: "",
      Meal: "",
      duration: "",
    },
  ]);
  const [details, setDetails] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };
  const doctorConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  const handleActionLoading = (value) => {
    setActionLoading(value);
  };

  useEffect(() => {
    fetchData();
    if (localStorage.getItem("doctorticket")) {
      fetchDoctorDepartment();
    }
    fetchMedicine();
  }, []);

  // const fetchMedicineName = (medId) => {
  //   setLoading(true);
  //   axios
  //     .get(`${apiBaseUrl}/medicine/${medId}`, config)
  //     .then((response) => {
  //       if (response.data.success) {
  //         setMedicineLabel(response.data.data.name);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching medicine details", error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const fetchData = () => {
    setLoading(true);

    axios
      .get(`${apiBaseUrl}/doctor/get_prescription/${appointmentId}`)
      .then((response) => {
        setDetails(response.data.data);
      })
      .catch((e) => {
        console.error("Failed to fetch prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDoctorDepartment = () => {
    setLoading(true);

    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setDoctorDepartment(response.data.data.department.department);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch prescription details:", e);
        message.error("Failed to get prescription details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMedicine = () => {
    axios
      .get(`${apiBaseUrl}/get/doctor/department/medicine`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  };

  const addPrescription = async (e) => {
    e.preventDefault();
    handleActionLoading(true);
    const data = {
      medicine: medicineList,
      follow_up: followUp,
    };
    try {
      let response;
      if (localStorage.getItem("doctorticket")) {
        response = await axios.post(
          `${apiBaseUrl}/doctor/prescription/create/${appointmentId}`,
          data,
          config
        );
      } else if (localStorage.getItem("staffticket")) {
        response = await axios.post(
          `${apiBaseUrl}/staff/prescription/create/${appointmentId}`,
          data,
          config
        );
      }
      if (response.data.success == true) {
        message.success("Prescription Added", 0.6, function onClose() {
          window.location.reload();
        });
      } else {
        message.error("Failed To add prescription");
      }
    } catch (error) {
      console.error("Failed to add prescription:", error);
      message.error("Failed To add prescription! Please try again.");
    } finally {
      handleActionLoading(false);
    }
  };
  const addMedicine = () => {
    setMedicineList([
      ...medicineList,
      {
        medicineName: "",
        frequency: "",
        Meal: "",
        duration: "",
      },
    ]);
  };

  const removeMedicine = (index) => {
    const updatedList = [...medicineList];
    updatedList.splice(index, 1);
    setMedicineList(updatedList);
  };

  const handleInputChange = (index, key, value) => {
    const updatedList = [...medicineList];
    updatedList[index][key] = value;
    setMedicineList(updatedList);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  const deletePrescription = async (
    prescription_id,
    appointmentId,
    full_name,
    problem
  ) => {
    if (window.confirm("Are you sure want to delete this prescription?")) {
      handleActionLoading(true);
      try {
        let response;
        if (localStorage.getItem("doctorticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/doctor/delete_prescription/${prescription_id}`,
            config
          );
        } else if (localStorage.getItem("staffticket")) {
          response = await axios.delete(
            `${apiBaseUrl}/staff/delete_prescription/${prescription_id}`,
            config
          );
        }

        if (response.data.success == true) {
          message.success("Prescription Deleted", 0.6, function onClose() {
            navigate(
              `/dashboard/viewOngoingAppointmentbyDoctor/add_prescription/${appointmentId}`,
              { state: { full_name, problem } }
            );
            window.location.reload();
          });
        } else {
          message.error("Failed to delete prescription.");
        }
      } catch (e) {
        console.error("Failed to delete prescription:", e);
        message.error("Failed to delete prescription! Please try again.");
      } finally {
        handleActionLoading(false);
      }
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="p-3 mb-5">
        <h1 className="text-3xl font-bold decoration-gray-400">Prescription</h1>
      </div>

      <div className="flex items-start">
        <div class="me-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow flex-grow">
          {loading && (
            <>
              <LoadingScreen />
            </>
          )}

          {!loading && (
            <>
              <p>
                <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900">
                  Patient Name:{" "}
                  <span className="font-semibold">{` ${full_name}`}</span>
                </h5>
              </p>
              <p class="mb-1 font-semibold text-gray-700">
                Problem: <span className="font-normal">{` ${problem}`}</span>
              </p>
              {details.length > 0 ? (
                details.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="me-2 mb-2 p-4 bg-white border border-gray-100 rounded-lg shadow flex-grow"
                    >
                      <p class="mb-1 font-semibold text-gray-700">
                        Prescription added on:{" "}
                        <span className="font-normal">
                          {formatDate(item.createdAt)}
                        </span>
                      </p>
                      <p class="mb-1 font-semibold text-gray-700">
                        Follow Up:{" "}
                        <span className="font-normal">{item.follow_up}</span>
                      </p>
                      <span class="mb-1 font-semibold text-gray-700">
                        Medicines:
                        <ul style={{ listStyleType: "disc" }} className="ps-4">
                          {item.medicine.map((medicineItem) => {
                            return (
                              <li className="font-normal">
                                <span className="font-medium">
                                  {medicineItem.medicineName}
                                </span>
                                {` || Frequency: ${medicineItem.frequency}`}
                                <br />
                                {`Duration: ${medicineItem.duration} || Meal: ${medicineItem.Meal}`}
                              </li>
                            );
                          })}
                        </ul>
                      </span>
                      <div class="flex justify-center mt-2">
                        <Link
                          to={`/dashboard/viewOngoingAppointmentbyDoctor/update_prescription/${item._id}`}
                          state={{ appointmentId: appointmentId }}
                        >
                          <button class="px-4 py-1 me-5 rounded-md bg-gray-600 text-sky-100 hover:bg-gray-700">
                            Update
                          </button>
                        </Link>
                        <Link
                          onClick={() => {
                            deletePrescription(
                              item._id,
                              appointmentId,
                              full_name,
                              problem
                            );
                          }}
                        >
                          <button class="px-4 py-1 rounded-md bg-red-700 text-sky-100 hover:bg-red-800">
                            Delete
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <p className="mt-3">
                    <h5 class="mb-1 text-xl text-red-500 font-semibold tracking-tight text-gray-900">
                      Prescription not found!!
                    </h5>
                  </p>
                </>
              )}
            </>
          )}
        </div>

        {/* form card */}
        <div class="block p-6 items-center bg-white border border-gray-200 rounded-lg shadow">
          <p class="text-xl mb-1 font-bold tracking-tight text-gray-800">
            Add Prescription
          </p>
          <div class="font-normal text-gray-700">
            <form
              className="pb-10 pt-5 mt-5 rounded-lg flex flex-col gap-4"
              onSubmit={addPrescription}
            >
              {medicineList.map((medicine, index) => (
                <div class="flex flex-wrap -mx-3 mb-2" key={index}>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-city"
                    >
                      Medicine Name
                    </label>
                    {doctorDepartment === "RHEUMATOLOGY" ? (
                      <Select
                        placeholder="Select a medicine"
                        showSearch
                        allowClear
                        className="w-full h-10 mt-0.2"
                        onChange={(value) => {
                          if (value) {
                            handleInputChange(index, "medicineName", value);
                          } else {
                            handleInputChange(index, "medicineName", "");
                          }
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {medicineOptions.map((med, index) => (
                          <Option key={index} value={med.name}>
                            {med.name}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      // <Select
                      //   options={medicineOptions}
                      //   placeholder="Select medicine"
                      //   // isClearable
                      //   onChange={(selectedMedicine) => {
                      //     if (selectedMedicine) {
                      //       handleInputChange(
                      //         index,
                      //         "medicineName",
                      //         selectedMedicine.medicine
                      //       );
                      //     } else {
                      //       handleInputChange(index, "medicineName", "");
                      //     }
                      //   }}
                      //   isSearchable
                      //   styles={{
                      //     control: (provided, state) => ({
                      //       ...provided,
                      //       borderRadius: "0.375rem",
                      //       color: "#4B5563",
                      //       borderColor: state.isFocused ? "#000000" : "#ccc",
                      //       boxShadow: state.isFocused
                      //         ? "0 0 0 1px rgba(0, 0, 0, 1)"
                      //         : null,
                      //       "&:hover": {},
                      //     }),
                      //   }}
                      // />
                      <input
                        class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                        type="text"
                        placeholder="Medicine name / dose"
                        value={medicine.medicineName}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "medicineName",
                            e.target.value
                          )
                        }
                      />
                    )}
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-state"
                    >
                      Frequency
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. 2 Times a day"
                      value={medicine.frequency}
                      onChange={(e) =>
                        handleInputChange(index, "frequency", e.target.value)
                      }
                    />
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-state"
                    >
                      Meal (After or before)
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. After meal"
                      value={medicine.Meal}
                      onChange={(e) =>
                        handleInputChange(index, "Meal", e.target.value)
                      }
                    />
                  </div>
                  <div class="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-xs font-semibold mb-2"
                      for="grid-zip"
                    >
                      Duration
                    </label>
                    <input
                      class="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                      type="text"
                      placeholder="eg.. 2 weeks"
                      value={medicine.duration}
                      onChange={(e) =>
                        handleInputChange(index, "duration", e.target.value)
                      }
                    />
                  </div>
                  <IconButton
                    onClick={() => removeMedicine(index)}
                    color="danger"
                    aria-label="minus button"
                  >
                    <AiFillMinusCircle />
                  </IconButton>
                </div>
              ))}
              <IconButton
                onClick={addMedicine}
                color="primary"
                aria-label="add button"
              >
                Add medicine field
                <HiPlusCircle />
              </IconButton>
              <div>
                <label className="block uppercase tracking-wide text-xs font-semibold mb-2">
                  Follow Up
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setFollowUp(e.target.value);
                  }}
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                  name="title"
                  placeholder="Enter follow up date"
                  required
                />
              </div>

              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddPrescription;
