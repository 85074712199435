import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const ReferDepartmentByDoctor = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId, departmentId } = useParams();

  let location = useLocation();
  const { full_name, problem } = location.state;
  const [department, setDepartment] = useState("");
  const [details, setDetails] = useState([]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get department details:", e);
        message.error(
          "Failed to get department details! Please try again later."
        );
      });
  }, []);

  const referDepartment = async (e) => {
    e.preventDefault();

    const data = {
      assigningDepartment: department,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/doctor/appointment/referDepartment/${appointmentId}`,
        data,
        config
      );
      if (response.data.msg == "Department assigned") {
        message.success("Patient refered success", 0.6, function onClose() {
          window.location.replace("/dashboard/viewReferredAppointmentbyDoctor");
        });
      } else {
        message.error("Failed To Refer");
      }
    } catch (err) {
      console.error("Error catched : ", err);
      message.error("Internal Server error");
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div className="block max-w-sm p-6 w-full items-center bg-white border border-gray-200 rounded-lg shadow">
          <div className="p-3 text-start">
            <Title level={2}>Refer Department</Title>
          </div>

          <p className="text-base -mt-1">
            Patient Name:{" "}
            <span className="text-gray-700 italic">{full_name}</span>
          </p>

          <p className="text-base">
            Problem: <span className="text-gray-700 italic">{problem}</span>
          </p>
          <div className="font-normal text-gray-700">
            <form
              className="pb-10 mt-3 rounded-lg flex flex-col gap-4"
              onSubmit={referDepartment}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label className="text-base">Select Department</label>
                  <select
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}
                    className="border-solid border-gray-200 border py-2 px-3 w-full rounded-md text-gray-600"
                    name="department"
                    required
                  >
                    <option value="">Select Department</option>
                    {details
                      .filter(
                        (singleDepartment) =>
                          singleDepartment._id !== departmentId
                      )
                      .map((singleDepartment, index) => (
                        <option key={index} value={singleDepartment.department}>
                          {singleDepartment.department}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>

              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReferDepartmentByDoctor;
