import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const apiService = {
  fetchBills: async () => {
    const response = await axios.get(`${apiBaseUrl}/bills`);
    return response.data;
  },

  createBill: async (billData) => {
    const response = await axios.post(`${apiBaseUrl}/bills`, billData);
    return response.data;
  },

  updateBill: async (billData) => {
    const response = await axios.put(
      `${apiBaseUrl}/bills/${billData._id}`,
      billData
    );
    return response.data;
  },

  fetchBillById: async (billId) => {
    const response = await axios.get(`${apiBaseUrl}/bills/${billId}`);
    return response.data;
  },
};

export default apiService;
