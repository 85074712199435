import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { Table, Input, Spin, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

const { Option } = Select;

const ViewAppointmentDateTime = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view_appointment/datetime`, config)
      .then((response) => {
        setDetails(response.data);
        setFilteredDetails(response.data);
      })
      .catch((error) => {
        console.error("Failed to get appointment date time details", error);
        toast.error(
          "Failed to get appointment date time details! Please try again later.",
          {
            position: "bottom-right",
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterDateTimes = useCallback(
    debounce((searchTerm, filterOption) => {
      let filteredAppointments = details.filter(
        (item) =>
          item?.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.doctorId?.fullname
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.doctorId?.department?.department
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );

      if (filterOption === "latest" || filterOption === "nearest") {
        const currentDate = new Date();
        const today = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        );

        filteredAppointments = filteredAppointments.filter((appointment) => {
          const appointmentDate = new Date(appointment.date);
          return appointmentDate >= today;
        });
      }

      if (filterOption === "latest") {
        filteredAppointments.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
      } else if (filterOption === "nearest") {
        filteredAppointments.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
      }

      setFilteredDetails(filteredAppointments);
    }, 500),
    [searchTerm, details]
  );

  const deleteAppointmentDateTime = async (datetimeId) => {
    try {
      if (
        window.confirm(
          "Are you sure want to delete this appointment date time?"
        )
      ) {
        setActionLoading(true);
        const response = await axios.delete(
          `${apiBaseUrl}/staff/appointment/delete/datetime/${datetimeId}`,
          config
        );
        if (response.data.success) {
          toast.success("Appointment Date Time Deleted", {
            position: "bottom-right",
          });
          fetchData();
        } else {
          toast.error("Failed to delete", {
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      console.error("Failed to delete appointment datetime:", error);
      toast.error("Failed to delete! Please try again.", {
        position: "bottom-right",
      });
    } finally {
      setActionLoading(false);
    }
  };

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
    filterDateTimes(value, filterOption);
  };

  const handleFilterChange = (value) => {
    setFilterOption(value);
    filterDateTimes(searchTerm, value);
  };

  const columns = [
    {
      title: "Doctor",
      dataIndex: ["doctorId", "fullname"],
      key: "doctorName",
    },
    {
      title: "Department",
      dataIndex: ["doctorId", "department", "department"],
      key: "department",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Available Time",
      dataIndex: "time",
      key: "availableTime",
      render: (date) => {
        if (date.length !== 0) {
          let renderedTimes = [];
          for (let i = 0; i < date.length; i += 2) {
            if (date[i + 1]) {
              renderedTimes.push(
                <span key={i}>
                  {date[i]}, {date[i + 1]}
                  <br />
                </span>
              );
            } else {
              renderedTimes.push(
                <span key={i}>
                  {date[i]}
                  <br />
                </span>
              );
            }
          }
          return <div>{renderedTimes}</div>;
        } else {
          return <span className="text-red-500">Unavailable</span>;
        }
      },
    },
    {
      title: "Booked Time",
      dataIndex: "bookedTime",
      key: "bookedTime",
      render: (date) => {
        if (date.length !== 0) {
          let renderedTimes = [];
          for (let i = 0; i < date.length; i += 2) {
            if (date[i + 1]) {
              renderedTimes.push(
                <span key={i}>
                  {date[i]}, {date[i + 1]}
                  <br />
                </span>
              );
            } else {
              renderedTimes.push(
                <span key={i}>
                  {date[i]}
                  <br />
                </span>
              );
            }
          }
          return <div>{renderedTimes}</div>;
        } else {
          return <span className="text-red-500">Unavailable</span>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/appointment_datetime/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700">
              Update
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteAppointmentDateTime(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md border border-dotted border-red-700 text-red-700 hover:bg-red-700 hover:text-white hover:border-red-800">
              Delete
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <div>Loading...</div>
        </div>
      )}

      <DashboardStatsGrid />
      <div className="container max-w-8xl mx-auto mt-8">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Appointment Date-Times
          </h1>
          <div className="flex justify-between">
            <div className="flex items-center mt-2">
              <Input
                placeholder="Search by Date or Name"
                value={searchTerm}
                onChange={(e) => handleSearchTermChange(e.target.value)}
                prefix={<SearchOutlined />}
                allowClear
              />
              <Select
                value={filterOption}
                onChange={handleFilterChange}
                style={{ width: 200, marginLeft: 8 }}
              >
                <Option value="">Filter by...</Option>
                <Option value="latest">Latest Date</Option>
                <Option value="nearest">Nearest Date</Option>
                <Option value="reset">Reset</Option>
              </Select>
            </div>
            <div>
              <Link to="/dashboard/add/appointment_datetime">
                <button className="px-4 py-2 rounded-md bg-sky-500 text-white hover:bg-sky-600">
                  Add DateTime
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViewAppointmentDateTime;
