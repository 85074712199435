import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const ErrorPageHandler = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(-1);
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button
            onClick={handleButtonClick}
            className="bg-blue-500"
            type="primary"
          >
            Go Back
          </Button>
        }
      />
    </div>
  );
};

export default ErrorPageHandler;
