import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import maleIcon from "../components/img/male-user-icon.png";
import femaleIcon from "../components/img/female-user-icon.png";
import otherIcon from "../components/img/others.png";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";

const User = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/viewpatientsstaff`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        toast.error("Failed to get user details! Please try again.", {
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletePatient = async (patient_id) => {
    if (window.confirm("Are you sure want to delete this user?")) {
      setActionLoading(true);
      try {
        const result = await axios.delete(
          `${apiBaseUrl}/patient/delete/` + patient_id,
          config
        );
        if (result.data.success) {
          toast.success("Success deletion", {
            position: "bottom-right",
          });
          window.location.replace("/dashboard/viewuser");
        } else {
          toast.error("Failed to delete", {
            position: "bottom-right",
          });
        }
      } catch (e) {
        console.error("Failed to delete user:", e);
        toast.error("Failed to delete user! Please try again.", {
          position: "bottom-right",
        });
      } finally {
        setActionLoading(false);
      }
    }
  };

  const filterUserDetails = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (user) =>
            user?.userID.toLowerCase().includes(searchTerm.toLowerCase()) ||
            // user?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user?.phone.toLowerCase().includes(searchTerm.toLowerCase())
          // ||
          // appointment?.DOB
          //   .toString()
          //   .toLowerCase()
          //   .includes(searchTerm.toLowerCase()) ||
          // appointment?.time.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchUsers();
      }
    }, 500),
    [searchTerm, details]
  );

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let years = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();
    let months = monthDifference < 0 ? monthDifference + 12 : monthDifference;
    let days;

    if (years < 1) {
      days = today.getDate() - birthDate.getDate();
      return `${days} days`;
    }

    if (years >= 1 && years < 5) {
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        years--;
        months = 12 - Math.abs(monthDifference);
      }

      let ageString = `${years} year${years !== 1 ? "s" : ""}`;
      if (months > 0) {
        ageString += `, ${months} month${months !== 1 ? "s" : ""}`;
      }
      return ageString;
    }

    if (years >= 5) {
      return `${years} years`;
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterUserDetails(searchTerm);
  };

  const columns = [
    {
      title: "Image",
      key: "picture",
      render: (_, record) => {
        const { picture, gender } = record;
        if (picture) {
          return (
            <Image
              src={picture}
              width={80}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          if (gender === "Male") {
            return (
              <Image
                src={maleIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          } else if (gender === "Female") {
            return (
              <Image
                src={femaleIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          } else {
            return (
              <Image
                src={otherIcon}
                width={80}
                height={80}
                className="object-contain rounded-md"
              />
            );
          }
        }
      },
    },
    {
      title: "UserID",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (email ? email : "-"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "DOB",
      dataIndex: "DOB",
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },
    {
      title: "Age",
      key: "age",
      render: (_, record) => {
        const { age, DOB } = record;
        if (DOB) {
          return calculateAge(DOB);
        } else {
          if (age) {
            return age + " years";
          } else {
            return <>-</>;
          }
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/patient/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deletePatient(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <DashboardStatsGrid />

      <div class="container max-w-8xl mx-auto mt-8">
        <div class="mb-4">
          <h1 class="text-3xl font-bold decoration-gray-400">Users</h1>
          <div class="flex justify-between mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <Link to="/dashboard/patientadd">
              <button class="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                Add Patient
              </button>
            </Link>
          </div>
        </div>
        <div class="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default User;
