import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              className="bg-blue-500"
              onClick={handleButtonClick}
            >
              Back Home
            </Button>
          }
        />
      </div>
    </>
  );
};
export default PageNotFound;
