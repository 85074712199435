import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import {
  Form,
  Row,
  Col,
  Input,
  Alert,
  message,
  Typography,
  Modal,
  Spin,
} from "antd";

const { Title } = Typography;

const ChangePassword = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const updatePassword = (values) => {
    const data = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmNewPassword,
    };

    if (localStorage.getItem("staffticket")) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("staffticket"),
        },
      };
      return axios
        .put(`${apiBaseUrl}/staff/change-password`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Password Updated", 0.6, function onClose() {
              setShowSpinner(true);
              setShowLogoutModal(true);
              const timeoutId = setTimeout(() => {
                handleLogout();
              }, 3000);

              return () => clearTimeout(timeoutId);
            });
          } else {
            const errorMessage =
              response.data.error ||
              "Failed to change password! Please try again.";
            message.error(errorMessage);
          }
        })
        .catch((error) => {
          console.error("Failed to change password:", error);
          const errorMessage =
            error.response?.data?.error ||
            "Failed to change password! Please try again.";
          message.error(errorMessage);
        });
    } else if (localStorage.getItem("doctorticket")) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("doctorticket"),
        },
      };
      return axios
        .put(`${apiBaseUrl}/doctor/change-password`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Password Updated", 0.6, function onClose() {
              setShowSpinner(true);
              setShowLogoutModal(true);
              const timeoutId = setTimeout(() => {
                handleLogout();
              }, 3000);

              return () => clearTimeout(timeoutId);
            });
          } else {
            const errorMessage =
              response.data.error ||
              "Failed to change password! Please try again.";
            message.error(errorMessage);
          }
        })
        .catch((error) => {
          console.error("Failed to change password:", error);
          const errorMessage =
            error.response?.data?.error ||
            "Failed to change password! Please try again.";
          message.error(errorMessage);
        });
    } else if (localStorage.getItem("labreporterticket")) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
        },
      };
      return axios
        .put(`${apiBaseUrl}/labreporter/change-password`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Password Updated", 0.6, function onClose() {
              setShowSpinner(true);
              setShowLogoutModal(true);
              const timeoutId = setTimeout(() => {
                handleLogout();
              }, 3000);

              return () => clearTimeout(timeoutId);
            });
          } else {
            const errorMessage =
              response.data.error ||
              "Failed to change password! Please try again.";
            message.error(errorMessage);
          }
        })
        .catch((error) => {
          console.error("Failed to change password:", error);
          const errorMessage =
            error.response?.data?.error ||
            "Failed to change password! Please try again.";
          message.error(errorMessage);
        });
    } else if (localStorage.getItem("pharmaticket")) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
        },
      };
      return axios
        .put(`${apiBaseUrl}/pharma/change-password`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Password Updated", 0.6, function onClose() {
              setShowSpinner(true);
              setShowLogoutModal(true);
              const timeoutId = setTimeout(() => {
                handleLogout();
              }, 3000);

              return () => clearTimeout(timeoutId);
            });
          } else {
            const errorMessage =
              response.data.error ||
              "Failed to change password! Please try again.";
            message.error(errorMessage);
          }
        })
        .catch((error) => {
          console.error("Failed to change password:", error);
          const errorMessage =
            error.response?.data?.error ||
            "Failed to change password! Please try again.";
          message.error(errorMessage);
        });
    }
  };

  const handleLogout = () => {
    message.success("You have been logged out");
    localStorage.clear();
    navigate("/", { replace: true });
  };
  return (
    <>
      <DashboardStatsGrid />

      <Form
        form={form}
        layout="vertical"
        onFinish={updatePassword}
        className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Change Password</Title>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="currentPassword"
              label="Current Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                className="rounded-md"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                className="rounded-md"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              name="confirmNewPassword"
              label="Confirm New Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input.Password
                placeholder="Enter password"
                className="rounded-md"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            id="addStaffBtn"
            className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form.Item>

        <Alert
          message="
          Forgotten your password? For assistance in resetting it, please contact our administrative department."
          type="warning"
          showIcon
        />
      </Form>

      <Modal
        open={showLogoutModal}
        closable={false}
        centered
        footer={null}
        afterClose={() => {
          setShowLogoutModal(false);
          setShowSpinner(false);
        }}
      >
        <div className="bg-white rounded-lg p-3 text-center">
          <p>You will be logged out and redirected to the login page.</p>
          <span className="flex justify-center items-center mt-2">
            <Spin spinning={showSpinner} />
          </span>
        </div>
      </Modal>
    </>
  );
};
export default ChangePassword;
