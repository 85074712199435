import { useState, useEffect } from "react";
import axios from "axios";
import DashboardStatsGrid from "../components/DashboardStatsGrid";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message, Row, Col, Form, Input, Typography } from "antd";

const { Title } = Typography;

const UpdateLabReport = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { reportId } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const { patient_fullName, patient_appointmentNumber } = location.state;
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };

  const handlePdfFileChange = (e) => {
    const file = e.target.files[0];
    setImageURL(URL.createObjectURL(file));
    form.setFieldsValue({ pdfFile: file });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view/single/report/${reportId}`, config)
      .then((response) => {
        if (response.data.success) {
          const report = response.data.data;
          form.setFieldsValue({
            reportName: report.reportName,
          });

          setImageURL(report.report);
        }
      })
      .catch((error) => {
        console.error("Error fetching lab reports:", error);
        message.error("Failed to get report data! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateLabReport = async (values) => {
    setActionLoading(true);

    const data = new FormData();
    data.append("reportName", values.reportName);
    data.append("pdfFile", values.pdfFile);

    const config = {
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
      },
    };
    try {
      const response = await axios.put(
        `${apiBaseUrl}/update-report/${reportId}`,
        data,
        config
      );
      if (response.data.success === true) {
        message.success(response.data.message, 0.6, function onClose() {
          window.location.replace("/dashboard/view/labreports");
        });
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      console.error("Error updating report:", error);
      message.error("Error updating report");
    } finally {
      setActionLoading(false);
    }
  };
  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}

      <DashboardStatsGrid />

      <Form
        form={form}
        onFinish={updateLabReport}
        layout="vertical"
        className="forbox w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-md"
      >
        <div>
          <Title level={2}>Update Report</Title>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Appointment Number"
              rules={[
                {
                  required: false,
                  message: "Please enter appointment number",
                },
              ]}
            >
              <Input value={patient_appointmentNumber} readOnly />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Patient Name"
              rules={[
                { required: false, message: "Please enter patient's name" },
              ]}
            >
              <Input value={patient_fullName} readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Report Name"
              name="reportName"
              rules={[{ required: true, message: "Please enter report name" }]}
            >
              <Input placeholder="Enter report name" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Report (PDF)"
          name="pdfFile"
          rules={[{ required: false, message: "Please upload a pdf." }]}
        >
          <input
            onChange={handlePdfFileChange}
            type="file"
            className="border border-gray-300 border py-2 px-3 w-full rounded-md text-gray-700"
          />
          {imageURL && (
            <div className="mt-3">
              <embed
                src={imageURL}
                className="rounded"
                type="application/pdf"
                width="100%"
                height="600px"
              />
            </div>
          )}
        </Form.Item>
        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
export default UpdateLabReport;
